import { DynamoDBClient, DynamoDBClientConfig } from "@aws-sdk/client-dynamodb"
import createGlobalState from "react-use/lib/factory/createGlobalState"

// app

const prefersDark = true
export const useGlobalDarkMode = createGlobalState<boolean>(prefersDark)

// dynamo

const devConfig = {
  region: "us-east-1",
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID_DEV ?? "",
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_DEV ?? "",
  },
}

const prodConfig = {
  region: "us-east-1",
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID ?? "",
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY ?? "",
  },
}

const localConfig = {
  region: "local",
  endpoint: "http:localhost:8000",
  credentials: {
    accessKeyId: "1",
    secretAccessKey: "2",
  },
}

let config: DynamoDBClientConfig = localConfig

if (process.env.NODE_ENV === "production") {
  config = prodConfig
  // eslint-disable-next-line no-constant-condition
} else {
  config = devConfig
}

config = prodConfig // TODO: check 

export const dbClient = new DynamoDBClient(config)

// terra
export const TERRA_BRIDGE = "https://walletconnect.terra.dev/"
