import { Brightness4, Brightness7 } from "@mui/icons-material"
import { Box, IconButton, Link, Stack } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useGlobalDarkMode } from "../config"

export const Footer = () => {
  const [theme, setTheme] = useGlobalDarkMode()

  const handleClick = () => {
    setTheme(!theme)
  }

  const navigate = useNavigate()

  return (
    <Box
      width={"100%"}
      display={"flex"}
      justifyContent={"space-between"}
      pt={"10px"}
      alignItems={"center"}
      height={"80px"}
    >
      <Box width={"30px"} />
      <Stack direction={"row"} spacing={"10px"}>
        <Link
          component="button"
          variant="body1"
          onClick={() => {
            navigate("/about")
          }}
        >
          about
        </Link>
        <Box>|</Box>
        <Link target={"_blank"} rel="noopener" href="https://twitter.com/arbuna_trade">
          twitter
        </Link>
        <Box>|</Box>
        <Link target={"_blank"} rel="noopener" href="https://discord.gg/m4zPCGea">
          discord
        </Link>
      </Stack>
      <IconButton color="primary" id="basic-button" onClick={() => handleClick()}>
        {theme ? <Brightness4 /> : <Brightness7 />}
      </IconButton>
    </Box>
  )
}
