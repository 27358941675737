import { Contracts, LightPool, Pool, PriceSet } from "shared"
/**
 * The app state items that are loaded in the initialization flow.
 *
 * One loading improvement could be that Chart and Swap check wallet
 * connectionStatus before loading data. They can load the view before  wallet
 * connection.
 *
 * Second loading improvement could be loading user and notifications in parallel
 * in useUser
 */

// Initial light pools info is loaded in App.tsx, together with loading terra.
export var pools: LightPool[]
export var _priceSets: PriceSet[]

export const setPools = (newPools: LightPool[]) => {
  pools = newPools
  // pools global var is set in App.tsx, before loading this file
  const obj: { [key: string]: LightPool[] } = {}

  pools.forEach((it) => {
    obj[it.name] = [...(obj[it.name] ?? []), it]
  })

  _priceSets = Object.entries(obj).map((it) => {
    var contracts: Partial<Contracts> = {}

    it[1].forEach((pool) => {
      contracts[pool.dex] = pool.dex
    })

    const priceSet: PriceSet = {
      chain: "t",
      pair: it[0],
      lightPools: it[1],
      pools: [],
    }
    return priceSet
  })
}

export const loadTestPools = async () => {
  const poolsString = (await import("../utils/testnet-pools")).testnetPools
  var JSONbig = await require("json-bigint")
  const pools = JSONbig.parse(poolsString) as Pool[]

  const priceSets = pools.map((it) => {
    const mergedPools = pools.flatMap((pool) => (pool.name === it.name ? [pool] : []))
    const priceSet: PriceSet = {
      chain: "t",
      pair: it.name,
      lightPools: mergedPools,
      pools: mergedPools,
    }
    return priceSet
  })

  const uniquePriceSets = priceSets.filter((newSet, index, builder) => {
    return builder.findIndex((existingSet) => existingSet.pair === newSet.pair) === index
  })

  _priceSets = uniquePriceSets
}

export const defaultFavourites = ["LunaX/LUNA"]

// Loaded in RoutedApp.tsx, with useUser. It is not loaded with pools because user needs
// wallet address to load. In App.tsx we dont have a wallet
export var selectedPairFromInitialLoad: string | undefined = undefined

export const setSelectedPairFromInitialLoad = (_selectedPairFromInitialLoad: string) => {
  selectedPairFromInitialLoad = _selectedPairFromInitialLoad
}
