import { Box, CircularProgress, Stack, useTheme } from "@mui/material"
import { WalletControllerChainOptions } from "@terra-money/wallet-provider"
import { lazy, Suspense, useEffect, useState } from "react"
import { BrowserRouter } from "react-router-dom"
import { readPoolsLight } from "shared"
import { Footer } from "./components/Footer"
import { Header } from "./components/Header"
import { dbClient, TERRA_BRIDGE } from "./config"
import { setPools } from "./hooks/AppLoadingState"

const loadingHeight = 300
// TODO: is there a better way? somehow input useGetPriceInfo the pools, instead of this global var.
// cant try and set priceSet to optional and add setPools method to usePrices

function App() {
  const theme = useTheme()

  let background = theme.palette.background.default
  if (theme.palette.mode === "dark") {
    background = "linear-gradient(-45deg, #803646 30%, #80472a 90%)"
  }

  const [chainOptions, setChainOptions] = useState<WalletControllerChainOptions | undefined>(
    undefined
  )

  const AppLoadingUser = lazy(() =>
    import("./AppLoadingUser").then(({ AppLoadingUser }) => ({
      default: AppLoadingUser,
    }))
  )

  const WalletProvider = lazy(() =>
    import("@terra-money/wallet-provider").then(({ WalletProvider }) => ({
      default: WalletProvider,
    }))
  )

  useEffect(() => {
    async function fetchData() {
      const importTerraJob = import("@terra-money/wallet-provider")

      const readPoolsJob = readPoolsLight(dbClient)
      const results = await Promise.all([importTerraJob, readPoolsJob])

      setPools(results[1].pools)
      setChainOptions(await results[0].getChainOptions())
    }
    fetchData()
  }, [])

  return (
    <BrowserRouter>
      <Box
        sx={{
          background: background,
        }}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        minHeight={"100vh"}
        padding={"10px"}
      >
        <Stack alignItems={"center"}>
          {chainOptions ? (
            <Suspense fallback={LoadingTerra()}>
              <WalletProvider
                {...chainOptions}
                connectorOpts={{
                  bridge: TERRA_BRIDGE,
                }}
              >
                <Header chainOptions={chainOptions} />
                <Box
                  sx={{ width: { xs: "95vw", md: "85vw" } }}
                  bgcolor={"background.paper"}
                  borderRadius="25px"
                  boxShadow="5"
                  textAlign={"center"}
                >
                  <Suspense fallback={LoadingApp()}>
                    <AppLoadingUser />
                  </Suspense>
                </Box>
              </WalletProvider>
            </Suspense>
          ) : (
            <LoadingTerra />
          )}
        </Stack>
        <Footer />
      </Box>
    </BrowserRouter>
  )
}

const LoadingTerra = () => {
  return (
    <>
      <Header />
      <Box
        sx={{ width: { xs: "95vw", md: "85vw" } }}
        bgcolor={"background.paper"}
        borderRadius="25px"
        boxShadow="5"
        textAlign={"center"}
      >
        <Stack height={loadingHeight} justifyContent={"center"} alignItems={"center"}>
          <CircularProgress />
          <p>Loading Terra...</p>
        </Stack>
      </Box>
    </>
  )
}

export const LoadingApp = () => {
  return (
    <>
      <Stack height={loadingHeight} justifyContent={"center"} alignItems={"center"}>
        <CircularProgress />
        <p>Loading App...</p>
      </Stack>
    </>
  )
}

export default App
