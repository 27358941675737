import { AttributeValue } from "@aws-sdk/client-dynamodb"

export type Chain = "t"
export type Dex = "ts" | "ap" | "px" // | "lp" | "pm"
export type DexName = "Terraswap" | "Astroport" | "Phoenix"

//github.com/terra-money/terra.kt/blob/bf484d19c0a87ff310e4d56241427375544aab56/types/src/commonMain/kotlin/money/terra/model/Coin.kt
export enum Denom {
  uluna = "uluna",
  uaud = "uaud",
  ucad = "ucad",
  uchf = "uchf",
  ucny = "ucny",
  udkk = "udkk",
  ueur = "ueur",
  ugbp = "ugbp",
  uhkd = "uhkd",
  uidr = "uidr",
  uinr = "uinr",
  ujpy = "ujpy",
  ukrw = "ukrw",
  umnt = "umnt",
  umyr = "umyr",
  unok = "unok",
  uphp = "uphp",
  usdr = "usdr",
  usek = "usek",
  usgd = "usgd",
  uthb = "uthb",
  utwd = "utwd",
  uusd = "uusd",
}

export enum DenomNative {
  LUNA = "LUNA",
  AUT = "AUT",
  CAT = "CAT",
  CHT = "CHT",
  CNT = "CNT",
  DKT = "DKT",
  EUT = "EUT",
  GBT = "GBT",
  HKT = "HKT",
  IDT = "IDT",
  INT = "INT",
  JPT = "JPT",
  KRT = "KRT",
  MNT = "MNT",
  MYT = "MYT",
  NOT = "NOT",
  PHT = "PHT",
  SDT = "SDT",
  SET = "SET",
  SGT = "SGT",
  THT = "THT",
  TWT = "TWT",
  UST = "UST",
}

export type AssetInfo = {
  cw20?: string
  native?: Denom
  // ^^ prism
  token?: {
    contract_addr: string
  }
  native_token?: {
    // denom or ibc address
    denom: Denom | string
  }
}

export type Token = {
  address: string
  // queried later
  symbol: string
  name: string
  protocol?: string
  decimals?: number
  circulatingSupply?: number
  icon?: string
  // manual
  website?: string
  twitter?: string
}

export type Pool = {
  address: string
  name: string
  token1: Token | Denom
  token2: Token | Denom
  token1AssetInfo: AssetInfo
  token2AssetInfo: AssetInfo
  token1Amount: BigInt
  token2Amount: BigInt
  offerAsset: number // 1 or 2
  totalShare?: BigInt
  dex: Dex
  volume24h?: BigInt // volume for single dex
  volume7d?: BigInt
}

export type LightPool = {
  name: string
  dex: Dex
}

export type PoolWithDex = Pool & {
  dex: Dex
}

// dynamo
export type DynamoItem = {
  [key: string]: AttributeValue
}
