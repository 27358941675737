import {
  DynamoDBClient,
  QueryCommand,
  QueryCommandInput,
  ScanCommandInput,
} from "@aws-sdk/client-dynamodb"
import { marshall, unmarshall } from "@aws-sdk/util-dynamodb"
import { poolsSameNameIndex, poolsTableName } from "../constants"
import { PriceSet } from "../contracts"
import { LightPool, Pool } from "../types"
import { findDuplicates } from "../utils"
import { batchScanAll } from "./batchHelper"

/**
 * If there are pools with same name in the same dex, then only 1 of them is returned in {pools}
 * Complete duplicates array is returned as {duplicates}
 * @returns The pools.
 */
export const readAllPoolsFullProjection = async (dbClient: DynamoDBClient) => {
  const input: ScanCommandInput = {
    TableName: poolsTableName,
  }

  const results = await batchScanAll(dbClient, input)

  let pools = results.map((it) => {
    const unmarshalled = unmarshall(it)

    const pool: Pool = {
      address: unmarshalled.address,
      name: unmarshalled.name,
      token1: JSON.parse(unmarshalled.token1),
      token2: JSON.parse(unmarshalled.token2),
      token1AssetInfo: JSON.parse(unmarshalled.token1AssetInfo),
      token2AssetInfo: JSON.parse(unmarshalled.token2AssetInfo),
      token1Amount: unmarshalled.token1Amount,
      token2Amount: unmarshalled.token2Amount,
      offerAsset: unmarshalled.offerAsset,
      totalShare: unmarshalled.totalShare,
      dex: unmarshalled.dex,
    }

    return pool
  })

  return pools
}

export const readPoolsLight = async (dbClient: DynamoDBClient) => {
  const input: ScanCommandInput = {
    TableName: poolsTableName,
    ProjectionExpression: "#name,dex",
    ExpressionAttributeNames: {
      "#name": "name",
    },
  }

  const results = await batchScanAll(dbClient, input)

  let pools = results.map((it) => {
    const unmarshalled = unmarshall(it)

    const pool: LightPool = {
      name: unmarshalled.name,
      dex: unmarshalled.dex,
    }

    return pool
  })

  const duplicates = findDuplicates(pools, (it) => {
    return it?.dex + it?.name
  })

  // If there are duplicates, retain a single random pool from them and send alert
  if (duplicates.length > 0) {
    duplicates.forEach((it) => {
      const duplicateIndex = pools.indexOf(it)
      pools = pools.splice(duplicateIndex, 1)
    })
  }

  return { pools, duplicates }
}

export const readPoolsForPriceSet = async (dbClient: DynamoDBClient, priceSet: PriceSet) => {
  const query: QueryCommandInput = {
    TableName: poolsTableName,
    IndexName: poolsSameNameIndex,
    KeyConditionExpression: "#name = :pairName AND begins_with(sk, :condition)",
    ExpressionAttributeNames: {
      "#name": "name",
    },
    ExpressionAttributeValues: marshall({
      ":pairName": `${priceSet.pair}`,
      ":condition": `t#${priceSet.pair}`,
    }),
  }

  const results = (await dbClient.send(new QueryCommand(query))).Items

  let pools = results?.map((it) => {
    const unmarshalled = unmarshall(it)

    const pool: Pool = {
      address: unmarshalled.address,
      name: unmarshalled.name,
      token1: JSON.parse(unmarshalled.token1),
      token2: JSON.parse(unmarshalled.token2),
      token1AssetInfo: JSON.parse(unmarshalled.token1AssetInfo),
      token2AssetInfo: JSON.parse(unmarshalled.token2AssetInfo),
      token1Amount: unmarshalled.token1Amount,
      token2Amount: unmarshalled.token2Amount,
      offerAsset: unmarshalled.offerAsset,
      totalShare: unmarshalled.totalShare,
      dex: unmarshalled.dex,
    }

    return pool
  })

  return pools ?? []
}
