import { AssetInfo, Denom, DenomNative, Dex, DexName, Pool, Token } from "./types"

export const findDuplicates = <T>(prices: T[], byPropertyToString: (item: T) => string) => {
  const lookup = prices.reduce((a: { [key: string]: any }, e) => {
    a[byPropertyToString(e)] = ++a[byPropertyToString(e)] || 0
    return a
  }, {})

  const duplicates = prices.filter((e) => lookup[byPropertyToString(e)])
  return duplicates
}

export const getDecimals = (pool: Pool) => {
  const firstNative = isNative(pool.token1AssetInfo)
  const secondNative = isNative(pool.token2AssetInfo)
  let firstDecimals: number, secondDecimals: number

  if (firstNative) {
    // we check denom before
    firstDecimals = 6
  } else {
    firstDecimals = (pool.token1 as Token).decimals ?? 6
  }

  if (secondNative) {
    secondDecimals = 6
  } else {
    secondDecimals = (pool.token2 as Token).decimals ?? 6
  }

  return { firstDecimals, secondDecimals }
}

/**
 * By default, we offer first token. If pool is reversed like some LUNA/UST pairs,
 * then we offer the second token.
 *
 * By offer asset, we mean we offer 1 LUNA and receive 89$ for LUNA/UST
 *
 * @param reverse Whether should return askDecimals
 */
export const getOfferDecimals = (pool: Pool, reverse = false) => {
  const { firstDecimals, secondDecimals } = getDecimals(pool)
  if (pool.offerAsset == 1 && !reverse) return firstDecimals
  return secondDecimals
}

export const getDexName = (protocol: Dex): DexName => {
  switch (protocol) {
    case "ts":
      return "Terraswap"
    case "ap":
      return "Astroport"
    case "px":
      return "Phoenix"
    // case "lp":
    //   return "Loop"
    // case "pm":
    //   return "Prism"
  }
}

export const isNative = (assetInfo: AssetInfo) => {
  return (
    (assetInfo.native !== undefined || assetInfo.native_token !== undefined) &&
    assetInfo.native_token?.denom.startsWith("ibc/") === false
  )
}

export const getNativeDenom = (assetInfo: AssetInfo): Denom | string | undefined => {
  if (assetInfo.native) return assetInfo.native
  if (assetInfo.native_token?.denom) return assetInfo.native_token.denom
  return undefined
}

export const getTokenAddress = (assetInfo: AssetInfo): string | undefined => {
  if (assetInfo.cw20) return assetInfo.cw20
  if (assetInfo.token?.contract_addr) return assetInfo.token.contract_addr
  // ibc address
  if (assetInfo.native_token?.denom && assetInfo.native_token?.denom.startsWith("ibc/"))
    return assetInfo.native_token?.denom
  return undefined
}

export const denomToSymbol = (denom: Denom) => {
  switch (denom) {
    case Denom.uluna:
      return DenomNative.LUNA
    case Denom.uaud:
      return DenomNative.AUT
    case Denom.ucad:
      return DenomNative.CAT
    case Denom.uchf:
      return DenomNative.CHT
    case Denom.ucny:
      return DenomNative.CNT
    case Denom.udkk:
      return DenomNative.DKT
    case Denom.ueur:
      return DenomNative.EUT
    case Denom.ugbp:
      return DenomNative.GBT
    case Denom.uhkd:
      return DenomNative.HKT
    case Denom.uidr:
      return DenomNative.IDT
    case Denom.uinr:
      return DenomNative.INT
    case Denom.ujpy:
      return DenomNative.JPT
    case Denom.ukrw:
      return DenomNative.KRT
    case Denom.umnt:
      return DenomNative.MNT
    case Denom.umyr:
      return DenomNative.MYT
    case Denom.unok:
      return DenomNative.NOT
    case Denom.uphp:
      return DenomNative.PHT
    case Denom.usdr:
      return DenomNative.SDT
    case Denom.usek:
      return DenomNative.SET
    case Denom.usgd:
      return DenomNative.SGT
    case Denom.uthb:
      return DenomNative.THT
    case Denom.utwd:
      return DenomNative.TWT
    case Denom.uusd:
      return DenomNative.UST
  }
}
