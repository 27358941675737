import { Box } from "@mui/material"
import { WalletControllerChainOptions } from "@terra-money/wallet-provider"
import { FunctionComponent, lazy, Suspense } from "react"
import { useNavigate } from "react-router-dom"

export const Header: FunctionComponent<{ chainOptions?: WalletControllerChainOptions }> = ({
  chainOptions,
}) => {
  // if there are chainOptions, then we are already embedded in WalletProvider
  const HeaderWallet = lazy(() =>
    import("./HeaderWallet").then(({ HeaderWallet }) => ({ default: HeaderWallet }))
  )

  const navigate = useNavigate()

  return (
    <Box
      sx={{ justifyContent: "space-between", p: 3 }}
      display="flex"
      alignItems="center"
      flexDirection="row"
      width={"100%"}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/")
        }}
      >
        <img
          alt="arbuna logo"
          style={{ width: "50px", height: "50px", marginRight: "10px" }}
          src={process.env.PUBLIC_URL + "/logo-192.png"}
        />
        <h1>Arbuna</h1>
      </Box>
      <Box display="flex" flexDirection="column" textAlign={"right"}>
        {chainOptions ? (
          <Suspense fallback="">
            <HeaderWallet />
          </Suspense>
        ) : null}
      </Box>
    </Box>
  )
}
