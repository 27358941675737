import { createTheme, CssBaseline } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import ReactDOM from "react-dom"
import ReactGA from "react-ga"
import App from "./App"
import { useGlobalDarkMode } from "./config"
import reportWebVitals from "./reportWebVitals"

const loggingLevel = process.env.NODE_ENV === "production"
if (loggingLevel) {
  console.log = function () {}
}

const themeLight = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#e4f0e2",
      paper: "#ffffff",
    },
  },
})

const themeDark = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#c28216",
    },
    secondary: {
      main: "#8D3044",
    },
    background: {
      default: "#222222",
      paper: "#2c251fB5",
    },
  },
})

// const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches

export const ThemedApp = () => {
  const [darkMode] = useGlobalDarkMode()

  // analytics
  ReactGA.initialize("UA-184773727-2", { testMode: process.env.NODE_ENV === "test" })
  ReactGA.pageview(window.location.pathname)

  return (
    <ThemeProvider theme={darkMode ? themeDark : themeLight}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  )
}

ReactDOM.render(
  <ThemedApp />,
  document.getElementById("root") || document.createElement("div") // for testing purposes
)

reportWebVitals()
